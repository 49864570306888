import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ title, description, image, pathname }) => {
  const {
    site: {
      siteMetadata: {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
        defaultImage,
      },
    },
    graphcms: { companyInfos },
  } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: url
          defaultImage: image
        }
      }
      graphcms {
        companyInfos {
          address
          phoneNumber
          contactEmail
          siret
          logo {
            url
          }
          name
          slogan
          postalCode
          town
          website
          region
          slogan
        }
      }
    }
  `);
  const seo = {
    title,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname ? `/${pathname}` : "/"}`,
  };
  const {
    address,
    phoneNumber,
    contactEmail,
    name,
    postalCode,
    town,
    logo,
    website,
    region,
    slogan,
  } = companyInfos[0];

  return (
    <>
      <Helmet
        title={seo.title}
        titleTemplate={titleTemplate}
        defaultTitle={defaultTitle}
      >
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {seo.url && <meta property="og:url" content={seo.url} />}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}
        <meta name="twitter:card" content="summary_large_image" />
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            address: {
              "@type": "PostalAddress",
              addressLocality: town,
              addressRegion: region,
              postalCode: postalCode,
              streetAddress: address,
            },
            description: slogan,
            name: name,
            telephone: phoneNumber,
            logo: logo.url,
            url: website,
          })}
        </script>
      </Helmet>
    </>
  );
};

export default SEO;

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  title: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
};
